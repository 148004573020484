.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1 1 auto;
	min-height: 100vh;
	font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

	transition: all 0.1s ease;
}

.container-light,
.container-light textarea,
.container-light button,
.container-light a {
	background-color: white;
	color: #212121;
}

.container-dark,
.container-dark textarea,
.container-dark button,
.container-dark a {
	background-color: #212121;
	color: white;
}

.toolbar {
	height: 30px;
	width: 90%;
	max-width: 728px;
	margin-left: 200px;
	transition: 0.5s;

	display: flex;
	color: #757575;
}

.toolbar button {
	color: #757575;
}

.button-on {
	font-weight: bold;
}

.toolbar button {
	cursor: pointer;
	height: 40px;
	width: 90px;
	display: flex;
	border: none;
	outline: none;
	font-size: 14px;
	padding: 0;
	margin: 0;
	background: none;
	align-items: center;
	justify-content: center;
}

.drawer {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 230px;
	flex-direction: column;
	padding: 65px 20px 65px 50px;
	z-index: 9;
	transition: 0.5s;
}

.drawer-light {
	background: rgba(255, 243, 224, 0.3);
	color: #212121;
}

.drawer-dark {
	background: #424242;
	color: #9E9E9E;
}

.drawer .heading {
	font-size: 14px;
	display: block;
	padding: 0;
	margin: 0;
}

.drawer .heading-top {
	margin-top: 15px;
}

.text-area {
	width: 90%;
	max-width: 728px;
	height: 100%;
	margin-top: 30px;
	margin-bottom: 50px;
	margin-left: 200px;
	transition: 0.5s;
}

.text-area textarea {
	display: block;
	font-size: 16px;
	width: 100%;
	height: 100%;
	resize: none;
	border: none;
	outline: none;
	font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
	background: none;
}

.controls {
	display: flex;
	align-items: center;
	max-width: 728px;
	margin-left: auto;
	background: none;
}

.controls p {
	font-size: 12px;
	margin: 0;
	padding: 0;
	margin-right: 15px;
}

.toggle-drawer {
	cursor: pointer;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 99;
	width: 230px;
	height: 40px;
	text-align: left;
	padding-left: 50px;
	border: none;
	background: transparent !important;
	outline: none;
}

.dropbox-action {
	width: 140px !important;
}

@media (min-width: 0px) and (max-width: 1280px) {
	.drawer {
		display: none !important;
	}

	.text-area {
		width: 90%;
		height: 100%;
		margin-top: 20px;
		margin-bottom: 50px;
		margin-left: 0 !important;
	}

	.toolbar {
		margin-left: 0 !important; 
	}

	.controls {
		position: fixed;
		bottom: 0;
		left: 0;
		height: 35px;
		width: 100%;
		z-index: 99;
		padding-left: 20px;
	}

	.toggle-drawer {
		display: none;
	}
}
